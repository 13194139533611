import * as React from "react"

import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout dark>
    <div className="not-found-page">
      <Container>
        <div className="error-page-containers">
          <div className="error-page-content">
            <h2>WE'RE SORRY, THE PAGE YOU WERE LOOKING FOR CANNOT BE FOUND.</h2>
            <p>
              This may be because it has been removed, we've changed its name or
              it is temporarily unavailable.
            </p>
          </div>
          <div className="eror-page-ctas">
            <Link className="button button-primary-color" to="/">
              Back to Home Page
            </Link>
            <Link className="button button-secondary-outline" to="/contact/">
              Contact
            </Link>
          </div>
        </div>
      </Container>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
